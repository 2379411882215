import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
let DrivePremium = class DrivePremium extends Vue {
    constructor() {
        super(...arguments);
        this.items = [
            {
                title: 'Jornada comercial',
                subtitle: `
        <ul>
          <li>Integração com as campanhas nacionais da KNN Idiomas;</li>
          <li>Landing page para captar cupons digitais com QR nas parcerias;</li>
          <li>Acompanhamento completo do funil de vendas comercial.</li>
        </ul>
      `
            },
            {
                title: 'Jornada pedagógico',
                subtitle: `
        <ul>
          <li>Reuniões: acompanhamento e agendamento;</li>
          <li>Cobrança direta pelo sistema e relatório de inadimplência;</li>
          <li>Prevenção de riscos de cancelamento;</li>
          <li>Acompanhamento das rematrículas.</li>
        </ul>
      `
            },
            {
                title: 'Integração bancária',
                subtitle: 'Cobrança PIX com baixa automática no Drive após o pagamento.'
            },
            {
                title: 'PIX - DrivePay',
                subtitle: 'Integração bancária sem precisar ter conta corrente.'
            },
            {
                title: 'Emissão de Nota Fiscal eletrônica',
                subtitle: 'NF-e, NFC-e e NFS-e. Apenas R$0,50 por nota.'
            },
            {
                title: 'Importação dos dados de forma gratuita',
                subtitle: 'Importação dos dados dos sistemas: Sponte e Matrischool'
            },
            {
                title: 'Integração com a plataforma Syngoo',
                subtitle: 'Automatize suas mensagens e centralize todos os seus canais de atendimento em uma única tela.'
            },
        ];
    }
    openWhatsApp() {
        const message = encodeURIComponent('Olá, tenho interesse em assinar o Drive Premium');
        const phoneNumber = '554791756371';
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        open(url);
    }
};
DrivePremium = __decorate([
    Component({
        components: {
            ButtonComponent
        }
    })
], DrivePremium);
export default DrivePremium;
